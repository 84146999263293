<template>
  <section id="home" class="iq-main-slider p-0 iq-rtl-direction">
    <div class="hideslide">
      <div class="slide slick-bg s-bg-1"
        :style="'background: url(' + data.image + '); padding: 100px 0 50px;width:100%; background-size:100% 100%;background-position: center center; background-repeat: no-repeat; height: 100vh; position: relative; z-index: 1;'">
        <b-container fluid class="position-relative h-100">
          <div class="slider-inner h-100">
            <b-row class="align-items-center  h-100 iq-ltr-direction">
              <b-col xl="6" lg="12" md="12" sm="12">
                <h1 class="slider-text big-title title text-uppercase" data-animation-in="fadeInLeft" data-delay-in="0.6">
                  {{ data.title }}</h1>
                <p data-animation-in="fadeInUp" data-delay-in="1.2">{{ data.description?.[$i18n.locale] }}</p>
                <div class="d-flex align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1.2">
                  <a href="#detail" class="btn btn-hover viewMoreBtn " id="viewMoreBtn">
                      <div v-if="$i18n.locale == 'en'"> View More </div>
                      <div v-if="$i18n.locale == 'ku'"> زیاتر نيشان بده </div>
                      <div v-if="$i18n.locale == 'ar'"> عرض المزيد </div>
                  </a>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </div>
  </section>
</template>

<style scoped>



.big-title {
  background: url(/img/login.25d845c2.jpg);
  background-clip: text;
  padding-bottom: 30px;
  font-size: 35px !important;
  padding-bottom: 10px;
  padding-top: 0px !important;
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;

}

.slick-bg {
background-size: 100% 100% !important;   
background-repeat: no-repeat; 
height: 480px !important;
    
    
    
    }

  
  p
  
  {
    
    font-weight: bold;
    font-size: 15px !important;
    color: rgb(255, 255, 255);



  }
  
  h1
  
  {
    padding-top: 100px !important;
  }

  @media screen and (max-width: 768px) {
    .slick-bg {
      background-size: 100% 100% !important;   
      background-repeat: no-repeat; 
      height: 480px !important;
    }

    h1 {
      font-size: 15px !important;
      padding-top: 80px !important;
    }
  }

  @media screen and (max-width: 350px) {
    .slick-bg {
      background-size: 100% 100% !important;   
      background-repeat: no-repeat; 
      height: 480px !important;
    }

    h1 {
      font-size: 15px !important;
      padding-top: 50px !important;
    }
  }

/* 







}



  background-repeat: repeat-x;
  background-position: 90% 90%;
  color: transparent;
  -webkit-font-smoothing: antialiased;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 1px;
  padding-bottom: 10px;


  
  @media (max-width: 800px) and (min-width: 280px) {
    background-size: cover;
    width: 100%;
    height: 100% !important;

  }

  

}











.btn-hover:before {
  background-color: #CC3EA0 !important;
  color: rgb(255, 255, 255);
  border-color: #CC3EA0;
align-items: end;

}

.btn-hover {

  background-color: #CC3EA0 !important;
  color: rgb(253, 253, 253);
  border-color: #CC3EA0;
}

.hover-buttons .btn {

  background-color: #CC3EA0 !important;
  color: rgb(245, 243, 243);
  border-color: #CC3EA0;
}


.btn-hover:before {
  background-color: #CC3EA0 !important;
  color: white;
  border-color: #CC3EA0;

}


*/
</style>














<script>
import ApiService from "../../../../services/api"

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    ApiService.getSetting().then((response) => {
      this.data = response.data.data
    })
  },
  data() {
    return {
      slideData: { title: 'Her Universe', age: '16', sesson: '2h 40m', text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.", src: require('@/assets/images/frontend/slider/slider2.jpg') },
      data: {}
    }
  }
}
</script>
